









































import { defineComponent, getCurrentInstance, SetupContext, toRef } from '@vue/composition-api'

import { useErrors } from '../../composables'

import FileUploadIcon from './icons/FileUploadIcon.vue'
import FileRemoveIcon from './icons/FileRemoveIcon.vue'

import { FileSelectProps } from './FileSelect.contracts'
import { fileSelectProps, useFileSelect } from './FileSelect.hooks'

/**
 * Component capable to render File Select element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const FileSelect = defineComponent({
  name: 'FileSelect',
  components: { FileRemoveIcon, FileUploadIcon },
  props: fileSelectProps,

  setup (props: FileSelectProps, ctx: SetupContext) {
    const internalInstance = getCurrentInstance()
    const { hasErrors } = useErrors(toRef(props, 'errors'), ctx.emit)

    return {
      hasErrors,
      ...useFileSelect(props, ctx.emit, internalInstance)
    }
  }
})
export default FileSelect
